
export const useAddonsStore = defineStore('addons', {
  state: () => {
    return {
      addons: [],
      total: 0,
      isAddonsLoading: false,
    };
  },

  actions: {
    async fetchAddons(params) {
      this.isAddonsLoading = true;

      this.addons = [];
      this.total = 0;

      const response = await useApi.get('/addons', {
        params,
      }).catch(() => {
        useToast.error(useT('abc288'));
      });

      if (response) {
        this.addons = response.results;
        this.total = response.total;
      }

      this.isAddonsLoading = false;

      return response;
    },
  },

  getters: {

  },
});
