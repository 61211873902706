<template>
  <div
    v-loading="!useProductsStore().prices"
    class="grid grid-cols-1 xl:grid-cols-3 gap-3 md:gap-4 overflow-auto w-full"
  >
    <PlanTier
      v-for="tier in tiers"
      :key="tier.name"
      class="h-full"
      :class="useProductsStore().prices ? '' : 'opacity-0'"
      :tier="tier"
      :is-current="props.currentPlan?.config.tier === tier.name"
      :is-disabled="isDisabled(tier)"
      @set-tier="(payload) => emit('set-tier', payload)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  currentPlan: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['set-tier']);

// Computed
const isDisabled = (tier) => {
  const tierIndex = tiers.value.findIndex(({ name }) => name === tier.name);
  const currentTierIndex = tiers.value.findIndex(({ name }) => name === props.currentPlan?.config.tier);

  return tierIndex < currentTierIndex;
};

const tiers = computed(() => ([
  {
    name: 'basic',
    title: useT('abc990'),
    isButtonRegular: !props.currentPlan,
    features: [
      {
        title: useT('abc989'),
      },
      {
        title: useT('abc981'),
      },
      {
        title: useT('abc982'),
      },
      {
        title: useT('abc122'),
      },
      {
        title: useT('abc151'),
      },
      {
        title: useT('abc986'),
      },
    ],
  },
  {
    name: 'professional',
    title: useT('abc991'),
    isPopular: !props.currentPlan,
    features: [
      {
        title: useT('abc1106'),
        isBold: true,
      },
      {
        title: useT('abc980', { count: 3 }),
      },
      {
        title: useT('abc983'),
      },
      {
        title: useT('abc984'),
      },
      {
        title: useT('abc985'),
      },
      {
        title: useT('abc128'),
      },
    ],
  },
  {
    name: 'enterprise',
    title: useT('abc992'),
    features: [
      {
        title: useT('abc1107'),
        isBold: true,
      },
      {
        title: useT('abc1090'),
      },
      {
        title: useT('abc166'),
      },
      {
        title: useT('abc873'),
      },
      {
        title: useT('abc135'),
      },
      {
        title: useT('abc987'),
      },
      {
        title: useT('abc26'),
      },
    ],
  },
]));

// Lifecycle
onMounted(async() => {
  if (!useProductsStore().prices) {
    await useProductsStore().fetchPrices();
  }
});
</script>
