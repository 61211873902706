<template>
  <div
    v-loading="isAirtableLoading"
    class="w-full flex gap-3"
  >
    <Select
      :model-value="props.tableId"
      name="tableId"
      :options="tables.map((item) => ({
        id: item.id,
        text: item.name,
      }))"
      :placeholder="useT('abc1052')"
      clearable
      @update:model-value="(payload) => emit('update:table-id', payload)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  apiKey: {
    type: String,
    default: null,
  },
  baseId: {
    type: String,
    default: null,
  },
  tableId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:table-id', 'update:view-id']);

// Refs
const isAirtableLoading = ref(false);
const tables = ref([]);

// Methods
const fetchAirtableTables = async() => {
  if (!props.apiKey || !props.baseId) {
    return;
  }

  isAirtableLoading.value = true;
  const response = await useWorkspaceStore().getAirtableTables({
    apiKey: props.apiKey,
    baseId: props.baseId,
  });
  tables.value = response || [];
  isAirtableLoading.value = false;
};

// Watchers
watch([() => props.apiKey, () => props.baseId], () => {
  fetchAirtableTables();
}, { immediate: true });
</script>
