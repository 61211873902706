<template>
  <Dialog
    v-model="useDialogStore().planOrder.isOpen"
    size="small-hug"
    unbounded
    @open="onOpen"
  >
    <template #header>
      <DialogHeader
        :title="isUpgrade ? useT('abc1128') : useT('abc1135')"
        show-close
        @close="useDialogStore().close('planOrder')"
      />
    </template>

    <div>
      <div class="p-6">
        <PlanItem
          v-model:max-capacity="maxCapacity"
          :plan="{
            type: 'singleUse',
            ...currentPlan,
            config: {
              ...currentPlan?.config,
              tier,
            },
          }"
        />
      </div>

      <el-collapse-transition>
        <div
          v-show="canContinue"
          class="bg-gray-100 p-6 pb-4"
        >
          <div
            v-if="!isUpgrade"
            class="flex justify-between text-sm mb-2"
          >
            <div>
              {{ useT('abc886', { value: _upperFirst(tier) }) }}
            </div>
            <div>
              {{ useFormatPrice(pricePerGuest, useWorkspaceStore().getCurrency) }}
              x
              {{ useT('abc889', { value: maxCapacity }) }}
            </div>
          </div>

          <template v-if="isUpgrade">
            <div
              v-if="isUpgrade"
              class="flex justify-between text-sm mb-2"
            >
              <div>
                {{ useT('abc889', { value: `${maxCapacity} ${_upperFirst(tier)}` }) }}
                <span class="text-xs text-gray-500">
                  ({{ useT('abc887', { value: useFormatPrice(pricePerGuest, useWorkspaceStore().getCurrency) }) }})
                </span>
              </div>
              <div>
                {{ useFormatPrice(maxCapacity * pricePerGuest, useWorkspaceStore().getCurrency) }}
              </div>
            </div>

            <div class="flex justify-between text-sm mb-2">
              <div class="flex items-center gap-1.5">
                <span>
                  {{ useT('abc889', { value: `${currentPlan?.config?.maxCapacity} ${_upperFirst(currentPlan?.config?.tier)}` }) }}
                  <span class="text-xs text-gray-500">
                    ({{ useT('abc887', { value: useFormatPrice(currentPlanPricePerGuest, useWorkspaceStore().getCurrency) }) }})
                  </span>
                </span>
                <Tooltip
                  :text="useT('abc1130')"
                />
              </div>
              <div>
                {{ useFormatPrice(-1 * currentPlan?.config?.maxCapacity * currentPlanPricePerGuest, useWorkspaceStore().getCurrency) }}
              </div>
            </div>
          </template>

          <div class="border-t border-gray-300 pt-2 flex justify-between">
            <div class="flex items-center">
              <div class="font-semibold">
                {{ useT('abc882') }}
              </div>
              <div class="ml-1 text-xs text-gray-600">
                ({{ useT('abc883') }})
              </div>
            </div>
            <div class="font-semibold">
              {{ useFormatPrice(totalPrice, useWorkspaceStore().getCurrency) }}
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <template
      #footer
    >
      <ButtonGroup
        align="right"
        type="primary"
        :text-submit="useT('xyz168')"
        :text-cancel="useT('xyz995')"
        :loading="isLoading"
        :disabled="!canContinue"
        @cancel="useDialogStore().close('planOrder')"
        @submit="onSubmit"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const maxCapacity = ref(200);
const isLoading = ref(false);

// Computed
const tier = computed(() => useDialogStore().planOrder.tier);
const pricePerGuest = computed(() => useProductsStore().prices?.[useWorkspaceStore().getCurrency]?.[`single_use_${tier.value}`]);
const currentPlan = computed(() => useDialogStore().planOrder.currentPlan);
const currentPlanPricePerGuest = computed(() => useProductsStore().prices?.[useWorkspaceStore().getCurrency]?.[`single_use_${currentPlan.value?.config?.tier}`]);
const currentPlanTotalPrice = computed(() => currentPlan.value?.config?.maxCapacity * currentPlanPricePerGuest.value);
const isUpgrade = computed(() => Boolean(currentPlan.value));

const totalPrice = computed(() => (maxCapacity.value * pricePerGuest?.value) - (isUpgrade.value ? currentPlanTotalPrice.value : 0));
const canContinue = computed(() => !currentPlan.value || (maxCapacity.value > currentPlan.value?.config?.maxCapacity || currentPlan.value?.config?.tier !== tier.value));

// Methods
const onOpen = () => {
  maxCapacity.value = currentPlan.value?.config?.maxCapacity || 200;
};

const onSubmit = async() => {
  if (isUpgrade.value) {
    isLoading.value = true;
    const response = await useProductsStore().createCheckout({
      productCategory: 'planUpgradeSingleUse',
      productData: {
        plan: currentPlan.value?._id,
        from: {
          tier: currentPlan.value?.config?.tier,
          maxCapacity: currentPlan.value?.config?.maxCapacity,
        },
        to: {
          tier: tier.value,
          maxCapacity: maxCapacity.value,
        },
      },
    });

    if (response) {
      await navigateTo(response.url, { external: true });
    } else {
      isLoading.value = false;
    }
  } else {
    // remove after remove invoice option

    if (useWorkspaceStore().stripe?.isCzechAccount) {
      useModalStore().open({
        type: 'info',
        title: useT('temp1'),
        description: useT('temp2'),
        submitButtonText: useT('temp3'),
        cancelButtonText: useT('temp4'),
        persistent: true,
        showClose: true,
        onSubmit: async() => {
          isLoading.value = true;

          const response = await useProductsStore().createCheckout({
            productCategory: 'planSingleUse',
            productKey: `single_use_${tier.value}`,
            productData: {
              tier: tier.value,
              maxCapacity: maxCapacity.value,
            },
          });
          if (response) {
            await navigateTo(response.url, { external: true });
          } else {
            isLoading.value = false;
          }
        },
        onCancel: async() => {
          isLoading.value = true;

          const plan = await usePlansStore().createSingleUsePlan({
            tier: tier.value,
            maxCapacity: maxCapacity.value,
          });

          if (plan) {
            useToast(useT('temp5'));
            useDialogStore().close('planOrder');
            useDialogStore().close('settings');
          }

          isLoading.value = false;
        },
      });

      return;
    }

    isLoading.value = true;
    const response = await useProductsStore().createCheckout({
      productCategory: 'planSingleUse',
      productKey: `single_use_${tier.value}`,
      productData: {
        tier: tier.value,
        maxCapacity: maxCapacity.value,
      },
    });
    if (response) {
      await navigateTo(response.url, { external: true });
    } else {
      isLoading.value = false;
    }
  }
};

// Lifecycle
onMounted(async() => {
  if (!useProductsStore().prices) {
    await useProductsStore().fetchPrices();
  }
});
</script>
