<template>
  <ActionList
    :items="[
      {
        id: 'personal',
        title: useAuthStore()?.user?.fullName,
        icon: 'user-circle',
      },
      {
        id: 'workspace',
        title: useT('abc22'),
        icon: 'settings-02',
      },
      {
        id: 'users',
        title: useT('abc23'),
        icon: 'users-02',
      },
      {
        id: 'plans',
        title: useT('abc24'),
        icon: 'package',
      },
      {
        id: 'billing',
        title: useT('abc25'),
        icon: 'file-05',
      },
      {
        id: 'integration',
        title: useT('abc26'),
        icon: 'puzzle-piece',
      },
      {
        id: 'logout',
        title: useT('abc51'),
        icon: 'log-out-04',
      }]"
    :groups="[
      {
        id: 'account',
        title: useT('abc20'),
        children: ['personal'],
      },
      ...workspaceGroup,
      {
        title: null,
        children: ['logout'],
      },
    ]"
    placement="bottom-end"
    class="popper-mobile-bottom"
    @click="onClick"
  >
    <button
      class="flex items-center gap-1"
      data-cy="open-dropdown-user"
    >
      <Avatar
        :image="useAuthStore()?.user?.profilePhoto"
        :name="useAuthStore()?.user?.fullName"
      />
    </button>
  </ActionList>
</template>

<script setup>
// Computed
const workspaceGroup = computed(() => useAuthStore().user?.role === 'admin'
  ? [
    {
      title: useT('abc21'),
      children: ['workspace', 'users', 'plans', 'billing', 'integration'],
    },
  ]
  : []);

// Methods
const onClick = (id) => {
  if (id === 'logout') {
    useAuthStore().logout();
    return;
  }

  if (id === 'billing') {
    useWorkspaceStore().openCustomerPortal();
    return;
  }

  useDialogStore().open('settings', { currentTab: id });
};
</script>
