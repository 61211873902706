<template>
  <Dialog
    v-model="useDialogStore().workspaceCustomDomainAdd.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header>
      <DialogHeader
        :title="useT('abc38')"
        show-close
        @close="useDialogStore().close('workspaceCustomDomainAdd')"
      />
    </template>

    <Form
      ref="formRef"
      :model="form"
      :rules="rules"
      :submit-on-enter="false"
      @submit="onSubmit"
    >
      <InputBlock
        :label="useT('abc196')"
        prop="url"
        class="mb-2"
      >
        <TextInput
          v-model="form.url"
          name="url"
          :placeholder="useT('abc197')"
          @blur="() => form.url = form.url.trim().replaceAll('https://', '').replaceAll('http://', '').split('/')[0]"
        />
      </InputBlock>
    </Form>

    <template #footer>
      <ButtonGroup
        type="primary"
        :text-submit="useT('abc12')"
        :text-cancel="useT('xyz995')"
        :loading="isSubmitting"
        @cancel="useDialogStore().close('workspaceCustomDomainAdd')"
        @submit="formRef.onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const formRef = ref(null);
const isSubmitting = ref(false);

const form = ref({
  url: '',
});

const rules = {
  url: [
    useFormRules().required,
    { regexp: true, pattern: /^(?!-)[A-Za-z0-9-]{1,63}(?:\.[A-Za-z0-9-]{1,63})*$/, message: useT('abc215'), trigger: 'submit' },
  ],
};

// Methods
const onOpen = () => {
  form.value = {
    url: useWorkspaceStore().integrations?.customDomain?.url || '',
  };
};

const onSubmit = async() => {
  isSubmitting.value = true;

  const response = await useWorkspaceStore().addCustomDomain({
    ...form.value,
  });

  await useWorkspaceStore().fetchWorkspace();

  isSubmitting.value = false;

  if (response) {
    useToast(useT('abc220'));
    useDialogStore().workspaceCustomDomainAdd.onSubmit(response);
    useDialogStore().close('workspaceCustomDomainAdd');
  }
};
</script>
