<template>
  <div class="border border-gray-200 rounded-lg p-4 sm:p-5 flex mb-4">
    <div class="bg-primary-100 rounded w-8 h-8 flex items-center justify-center mr-4 mt-1.5 flex-shrink-0">
      <Icon
        :name="props.icon"
        class="text-primary-500 w-4 h-4"
      />
    </div>
    <div class="flex-1 flex flex-col sm:flex-row justify-between gap-4 sm:gap-8">
      <div>
        <div class="flex items-center gap-2 mb-0.5">
          <h3 class="text-base font-medium max-w-lg">
            {{ props.title }}
          </h3>
          <Badge
            v-if="props.badge"
            :text="props.badge.text"
            :type="props.badge.type"
          />
        </div>
        <p class="text-sm text-gray-500 max-w-lg">
          {{ props.description }}
        </p>
      </div>
      <div>
        <a
          :href="`mailto:${useAuthStore().user?.locale === 'cs' ? 'podpora@attendu.com' : 'support@attendu.com'}`"
          target="_blank"
        >
          <Button
            type="regular-outline"
            icon-left="mail-02"
          >
            {{ useT('abc899') }}
          </Button>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  badge: {
    type: Object,
    default: null,
  },
  description: {
    type: String,
    default: '',
  },
});
</script>
