<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useT('abc23')"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div class="py-16">
        <div class="flex justify-between p-4 sm:p-6 gap-4">
          <TextInput
            v-model="searchQuery"
            :placeholder="useT('abc54')"
            class="w-full max-w-[22.5rem]"
            search
          />

          <Button
            type="primary"
            icon-left="user-plus-01"
            data-cy="invite-user"
            @click="useDialogStore().open('userInvite', { onSubmit: () => fetchUsers() })"
          >
            {{ grid.sm ? useT('abc1079') : useT('abc1055') }}
          </Button>
        </div>

        <div class="px-4 sm:px-6 w-full">
          <el-table
            v-if="!isLoading"
            :data="usersFiltered"
            row-key="_id"
          >
            <el-table-column
              prop="user"
              :label="useT('abc56')"
              sortable
            >
              <template #default="scope">
                <div class="flex items-center gap-3">
                  <Avatar
                    :image="scope.row?.profilePhoto"
                    :name="scope.row?.fullName"
                    size="md"
                  />
                  <div class="text-sm truncate">
                    <div class="text-gray-900 font-medium truncate">
                      {{ scope.row.fullName }}
                    </div>
                    <div class="text-gray-600 truncate">
                      {{ scope.row.email }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="role"
              :label="useT('abc57')"
              :width="grid.sm ? '200' : ''"
              align="right"
              sortable
            >
              <template #default="scope">
                <div class="flex justify-end">
                  <ActionList
                    :items="getRoleOptions(scope.row.role)"
                    placement="bottom-end"
                    @click="(role) => changeUserRole({ role, user: scope.row })"
                  >
                    <Button
                      size="small"
                      type="regular-text"
                      icon-right="chevron-down"
                      data-cy="user-role"
                    >
                      {{ useT(scope.row.role) }}
                    </Button>
                  </ActionList>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-show="isLoading"
            v-loading="isLoading"
            class="w-full h-48"
          />
          <div
            v-if="usersFiltered.length"
            class="el-table__counter"
          >
            {{ useT('abc18', { n: usersFiltered.length }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Refs
const isLoading = ref(false);
const searchQuery = ref('');

// Computed
const usersFiltered = computed(() => filterSearchInFields({
  items: useUsersStore().users,
  fields: ['fullName', 'email'],
  query: searchQuery.value,
}));

// Methods
const getRoleOptions = (role) => ([
  ...useUsersStore().roles.map((item) => ({ id: item, title: useT(item), description: useT(`users.${item}`), isDisabled: item === role })),
  { id: 'delete', title: useT('abc42'), isDanger: true },
]);

const fetchUsers = () => {
  isLoading.value = true;
  useUsersStore().fetchUsers().then(() => isLoading.value = false);
};

const changeUserRole = ({ role, user }) => {
  if (user.role === 'admin') {
    if (role === 'creator') {
      return useModalStore().open({
        type: 'warning',
        title: useT('abc965'),
        description: useT('abc966'),
        submitButtonText: useT('abc968'),
        cancelButtonText: useT('xyz995'),
        onSubmit: () => useUsersStore().changeUserRole({ role, user }),
      });
    }

    if (role === 'editor') {
      return useModalStore().open({
        type: 'warning',
        title: useT('abc967'),
        description: useT('abc966'),
        submitButtonText: useT('abc969'),
        cancelButtonText: useT('xyz995'),
        onSubmit: () => useUsersStore().changeUserRole({ role, user }),
      });
    }
  }

  if (role === 'delete') {
    return useModalStore().open({
      type: 'danger',
      title: useT('abc993', { value: user.fullName || user.email || '' }),
      description: useT('abc970'),
      submitButtonText: useT('abc743'),
      cancelButtonText: useT('xyz995'),
      onSubmit: () => useUsersStore().deleteUser(user),
    });
  }

  useUsersStore().changeUserRole({ role, user });
};

// Lifecycle
onMounted(() => fetchUsers());
</script>
