<template>
  <header class="fixed top-0 left-0 w-full z-[1000] py-2 border-b border-gray-200 bg-white h-14 px-4 md:px-6">
    <div
      class="transition-all flex justify-between items-center w-full"
      :class="{
        'ml-[var(--mobile-navigation-width)] md:ml-0': useGeneralStore().isBurgerMenuOpen && isEventPage,
      }"
    >
      <MainHeaderEvent
        :class="isEventPage ? 'hidden md:flex' : 'flex'"
      />
      <div
        v-if="isEventPage"
        class="block md:hidden"
      >
        <Button
          type="regular-text"
          icon-left="menu-01"
          @click="useGeneralStore().isBurgerMenuOpen = !useGeneralStore().isBurgerMenuOpen"
        />
      </div>

      <div class="flex items-center gap-4">
        <Button
          v-if="useRoute().name === 'events' && useAuthStore().user?.role === 'admin'"
          icon-left="building-07"
          @click="() => useDialogStore().open('settings', { currentTab: 'workspace' })"
        >
          <span class="hidden sm:inline-block">
            {{ useT('abc21') }}
          </span>
        </Button>
        <MainHeaderUser />
      </div>
    </div>
  </header>
</template>

<script setup>
// Computed
const isEventPage = computed(() => !!((useRoute()?.name || '').includes('events-_id') && useEventStore()?.event));
</script>
