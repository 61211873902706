<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useT('abc24')"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div
        v-if="isActivePlansLoading || isAddonsLoading"
        v-loading="true"
        element-loading-custom-class="[&>.el-loading-spinner]:top-56"
      />
      <div
        v-else
        class="py-16 px-4 sm:px-6 w-full"
      >
        <PlanTiers
          class="mt-6 mb-8"
          @set-tier="(tier) => useDialogStore().open('planOrder', { tier, currentPlan: null })"
        />

        <div class="space-y-4">
          <PlanPromotionBox
            v-if="!hasActiveSubscription"
            :title="useT('abc1091')"
            icon="infinity"
            :badge="{
              text: useT('abc1092'),
              type: 'success',
            }"
            :description="useT('abc1093')"
            upgradeable
          />

          <PlanPromotionBox
            v-if="!hasActiveDedicatedSupport"
            :title="useT('abc1094')"
            icon="phone-call-01"
            :description="useT('abc1095')"
          />
        </div>

        <div class="mt-12 sm:mt-20">
          <h2 class="text-lg font-bold mb-3">
            {{ useT('abc1096') }}
          </h2>

          <div
            v-loading="isActivePlansLoading"
            class="space-y-3"
          >
            <PlanAddonItem
              v-if="hasActiveDedicatedSupport"
              icon="phone-call-01"
              :title="useT('abc1094')"
              is-active
            />

            <PlanItem
              v-for="plan in activePlans"
              :key="plan._id"
              :plan="plan"
              expandable
              upgradeable
            />

            <EmptyState
              v-if="!activePlans?.length"
              icon="package"
              :title="useT('abc19')"
            />
          </div>
        </div>

        <div
          v-if="pastPlans?.length || isPastPlansLoading"
          class="mt-10 sm:mt-16"
        >
          <h2 class="text-lg font-bold mb-3">
            {{ useT('abc1097') }}
          </h2>

          <div
            v-loading="isPastPlansLoading"
            class="space-y-3"
          >
            <PlanAddonItem
              v-if="hasExpiredDedicatedSupport"
              icon="phone-call-01"
              :title="useT('abc1094')"
            />

            <PlanItem
              v-for="plan in pastPlans"
              :key="plan._id"
              :plan="plan"
              expandable
              show-status
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Refs
const activePlans = ref([]);
const isActivePlansLoading = ref(false);

const pastPlans = ref([]);
const isPastPlansLoading = ref(false);
const isAddonsLoading = ref(false);

// Computed
const hasActiveDedicatedSupport = computed(() => useAddonsStore().addons.some((addon) => addon.name === 'dedicatedSupport' && addon.status === 'active'));
const hasExpiredDedicatedSupport = computed(() => useAddonsStore().addons.some((addon) => addon.name === 'dedicatedSupport' && addon.status === 'expired'));
const hasActiveSubscription = computed(() => activePlans.value.some((plan) => plan.type === 'subscription'));

// Methods
const fetchActivePlans = async() => {
  isActivePlansLoading.value = true;

  const response = await usePlansStore().fetchPlans({
    status: ['active'],
  });

  activePlans.value = response?.results || [];

  isActivePlansLoading.value = false;
};

const fetchPastPlans = async() => {
  isPastPlansLoading.value = true;

  const response = await usePlansStore().fetchPlans({
    status: ['used', 'expired', 'deactivated'],
  });

  pastPlans.value = response?.results || [];

  isPastPlansLoading.value = false;
};

const fetchAddons = async() => {
  isAddonsLoading.value = true;
  await useAddonsStore().fetchAddons();
  isAddonsLoading.value = false;
};

// Lifecycle
onMounted(() => {
  fetchActivePlans();
  fetchPastPlans();
  fetchAddons();
});
</script>
