<template>
  <div>
    <Dialog
      v-model="useDialogStore().settings.isOpen"
      size="large"
      unbounded
    >
      <div class="flex h-full">
        <div class="overflow-auto h-full w-60 min-w-[15rem] hidden md:block">
          <Navigation
            :model-value="useDialogStore().settings.currentTab"
            class="bg-gray-100 border-r p-2 flex-shrink-0 w-full min-h-full"
            :sections="sections"
            @update:model-value="(payload) => useDialogStore().settings.currentTab = payload"
          />
        </div>
        <div class="flex-1">
          <MainSettingsDialogPersonal
            v-if="useDialogStore().settings.currentTab === 'personal'"
          />
          <MainSettingsDialogWorkspace
            v-if="useDialogStore().settings.currentTab === 'workspace'"
          />
          <MainSettingsDialogUsers
            v-if="useDialogStore().settings.currentTab === 'users'"
          />
          <div
            v-if="useDialogStore().settings.currentTab === 'billing'"
            v-loading="true"
            class="h-full"
          />
          <MainSettingsDialogPlans
            v-if="useDialogStore().settings.currentTab === 'plans'"
          />
          <MainSettingsDialogIntegration
            v-if="useDialogStore().settings.currentTab === 'integration'"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
// Refs
const sections = ref([
  {
    title: useT('abc20'),
    items: [
      {
        id: 'personal',
        title: useAuthStore()?.user?.fullName,
        icon: 'user-circle',
      },
    ],
  },
]);

// Watch
watch(() => useDialogStore().settings.currentTab, async(currentTab) => {
  if (currentTab === 'billing') {
    useDialogStore().close('settings');
    await useWorkspaceStore().openCustomerPortal();
  }
});

// if route changed, close dialog
watch(() => useRoute().path, () => {
  useDialogStore().close('settings');
});

// Lifecycle
onBeforeMount(() => {
  if (useAuthStore().user?.role === 'admin') {
    sections.value.push({
      title: useT('abc21'),
      items: [
        {
          id: 'workspace',
          title: useT('abc22'),
          icon: 'settings-02',
        },
        {
          id: 'users',
          title: useT('abc23'),
          icon: 'users-02',
        },
        {
          id: 'plans',
          title: useT('abc24'),
          icon: 'package',
        },
        {
          id: 'billing',
          title: useT('abc25'),
          icon: 'file-05',
        },
        {
          id: 'integration',
          title: useT('abc26'),
          icon: 'puzzle-piece',
        },
      ],
    });
  }
});
</script>
